import mediumPlexSansLatin1 from "fonts/IBMPlexSans-Medium-Latin1.woff2"
import boldPlexSansLatin1 from "fonts/IBMPlexSans-Bold-Latin1.woff2"

import { createGlobalStyle } from "styled-components"
export const FontFaces = createGlobalStyle`

@font-face {
  font-family: "IBM Plex Sans";
  font-style: normal;
  font-weight: 300;
  src: url(${mediumPlexSansLatin1});
}

@font-face {
  font-family: "IBM Plex Sans";
  font-style: normal;
  font-weight: 500;
  src: url(${boldPlexSansLatin1});
}
`
