import React from "react"
import PropTypes from "prop-types"

import Header from "./Header"
import { GlobalStyle } from "./Reset"
import { FontFaces } from "./Fonts"
import styled, { ThemeProvider } from "styled-components"

const StyledLayout = styled.div`
  padding: 1rem;
  font-family: "IBM Plex Sans", sans-serif;

  @media (min-width: ${props => props.theme.breakpoint}) {
    width: 50rem;
    margin: 0 auto;
  }
`

/* const lightTheme = {
  foreground: "hsla(0, 0%, 0%, 0.8)", //"Dark Gray"
  foregroundAccent: "hsla(0, 0%, 0%, 0.8)",
  main: "hsla(0, 0%, 0%, 0.8)",
  backgroundAccent: "#fff",
  background: "#fff",
}

const darkTheme = {
  background: "hsla(0, 0%, 0%, 0.8)",
  backgroundAccent: "hsla(0, 0%, 0%, 0.8)",
  main: "#fff",
  foregroundAccent: "#fff",
  foreground: "#fff",
} */

const oceanTheme = {
  background: "#22577a", //"Blue Sapphire"
  backgroundAccent: "#38a3a5", //"Cadet Blue"
  main: "#57cc99", //"Medium Aquamarine"
  foregroundAccent: "#80ed99", //"Light Green"
  foreground: "#c7f9cc", //"Tea Green"
  breakpoint: "60rem",
  titleFont: `"Montserrat", sans-serif`,
}

/* const candyTheme = {
  main: "#db2763", //"Cerise"
  foregroundAccent: "#b0db43", // "June Bud"
  foreground: "#12eaea", // "Fluorescent Blue"
  background: "#bce7fd", //"Uranian Blue"
  backgroundAccent: "#c492b1", // "Opera Mauve"
} */

//const themes = [lightTheme, darkTheme, oceanTheme]
//const randomTheme = themes[Math.floor(Math.random() * themes.length)]

const Layout = ({ children }) => {
  return (
    <ThemeProvider theme={oceanTheme}>
      <GlobalStyle />
      <FontFaces />
      <StyledLayout>
        <Header />
        <main>{children}</main>
        <footer>
          All rights reserved © Liam Hawks {new Date().getFullYear()}
        </footer>
      </StyledLayout>
    </ThemeProvider>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
